import React from 'react';
import { flattenGrid } from '~/helpers/cms.helper';
import { usePage } from '~/libs/queries/bff';
import PageElements from '~/page-elements';

const FrontPage = () => {
    const { data: pageContent } = usePage((page) => ({ id: page.id, grid: page.grid }));

    return <PageElements pageElements={flattenGrid(pageContent?.grid)} pageId={pageContent?.id as string} />;
};

export default FrontPage;
